<template>
    <el-main>
        <el-form label-width="140px">
            <el-tabs v-model="tab_active" @tab-click="tabClick">
                <el-tab-pane name="banner" label="banner管理">
                    <el-form-item label="banner图：" required>
                        <div class="listItem">
                            <vuedraggable
                            v-model="banner"
                            v-bind="{
                                animation: 200,
                                group: 'description',
                                disabled: false,
                                ghostClass: 'ghost',
                                scroll: true
                            }">
                                <transition-group>
                                    <div class="itemBox" v-for="(item, index) in banner" :key="index">
                                        <div class="left">
                                            <div class="leftL">
                                                <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''"
                                                    >&lt;</span
                                                >
                                                <span
                                                    @click="moveClick(1, index, item)"
                                                    :class="index == banner.length - 1 ? 'fcccc' : ''"
                                                    >></span
                                                >
                                            </div>
                                            <div class="leftR" @click="chooseImg('banner',index)">
                                                <img :src="item.imgURL" alt="img" />
                                                <div class="leftR_text">选择图片</div>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <div style="margin-right: 15px;">
                                                <div class="right-top">
                                                    <el-radio-group v-model="item.choosePagetype">
                                                        <el-radio label="page">系统页面</el-radio>
                                                        <el-radio label="webview">第三方链接</el-radio>
                                                    </el-radio-group>
                                                </div>
                                                <div >
                                                    <div class="inputBox" v-if="item.choosePagetype == 'page'">
                                                        <el-input v-model="item.toPage" disabled class="inputstyle"></el-input>
                                                        <el-button type="primary" @click="choosePage('banner',index)">选择</el-button>
                                                    </div>
                                                    <div class="inputBox" v-else>
                                                        <el-input v-model="item.toPage" placeholder="输入时请携带http或https" class="inputstyle"></el-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click="deleteItem('banner',index)" v-if="index>0" style="cursor: pointer;">
                                            <i class="iconfont icon-guanbi"></i>
                                        </div>
                                    </div>
                                </transition-group>
                            </vuedraggable>
                        </div>
                        <div @click="addBanner" class="addOne">新增一个</div>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane name="notice" label="公告管理">
                    <el-form-item label="公告管理：" required>
                        <div class="pageName">
                            <p>选择图片</p>
                            <div class="itemMid">
                                <img :src="notice.icon" alt="icon" class="imgBox" v-if="notice.icon"/>
                                <div class="upload" @click="chooseImg('notice')">选择图片</div>
                            </div>
                            <div class="itemrright">
                                <div class="tips"></div>
                                <div class="clearImg" @click="notice.icon=''">清除图片</div>
                            </div>
                        </div>
                        <vuedraggable v-model="notice.list" v-bind="{
                            animation: 200,
                            group: 'description',
                            disabled: false,
                            ghostClass: 'ghost',
                            scroll: true,
                        }">
                            <transition-group>
                                <div v-for="(item, index) in notice.list" :key="index">
                                    <div class="pageName">
                                        <p>公告标题</p>
                                        <el-input v-model="item.title" placeholder="请输入公告标题"></el-input>
                                    </div>
                                    <div class="pageName">
                                        <p>跳转路径</p>
                                        <el-input disabled v-model="item.toPage"></el-input>
                                        <el-button type="primary" @click="choosePage('notice',index)">选择</el-button>
                                        <div @click="deleteItem('notice',index)" v-if="index>0" style="cursor: pointer;margin-left: 20px;">
                                            <i class="iconfont icon-guanbi"></i>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </vuedraggable>
                        <div @click="addNotice" class="addOne">新增一个</div>
                    </el-form-item>
                </el-tab-pane>
                <el-tab-pane name="sign_banner" label="引导图" >
                    <el-form-item label="引导图：" required>
                        <div class="itemBox">
                            <div class="left">
                                <div class="leftR" @click="chooseImg('sign_banner',index)">
                                    <img :src="sign_banner.imgURL" alt="img" />
                                    <div class="leftR_text">选择图片</div>
                                </div>
                            </div>

                            <div class="right">
                                <div style="margin-right: 15px;">
                                    <div class="right-top">
                                        <el-radio-group v-model="sign_banner.choosePagetype">
                                            <el-radio label="page">系统页面</el-radio>
                                            <el-radio label="webview">第三方链接</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div >
                                        <div class="inputBox" v-if="sign_banner.choosePagetype == 'page'">
                                            <el-input v-model="sign_banner.toPage" disabled class="inputstyle"></el-input>
                                            <el-button type="primary" @click="choosePage('sign_banner')">选择</el-button>
                                        </div>
                                        <div class="inputBox" v-else>
                                            <el-input v-model="sign_banner.toPage" placeholder="输入时请携带http或https" class="inputstyle"></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-tab-pane>
                <!-- <el-tab-pane name="sign_banner" label="模块装修" >
                    <el-form-item label="引导图：" required>
                        <div class="itemBox">
                            <div class="left">
                                <div class="leftR" @click="chooseImg('sign_banner',index)">
                                    <img :src="sign_banner.imgURL" alt="img" />
                                    <div class="leftR_text">选择图片</div>
                                </div>
                            </div>

                            <div class="right">
                                <div style="margin-right: 15px;">
                                    <div class="right-top">
                                        <el-radio-group v-model="sign_banner.choosePagetype">
                                            <el-radio label="page">系统页面</el-radio>
                                            <el-radio label="webview">第三方链接</el-radio>
                                        </el-radio-group>
                                    </div>
                                    <div >
                                        <div class="inputBox" v-if="sign_banner.choosePagetype == 'page'">
                                            <el-input v-model="sign_banner.toPage" disabled class="inputstyle"></el-input>
                                            <el-button type="primary" @click="choosePage('sign_banner')">选择</el-button>
                                        </div>
                                        <div class="inputBox" v-else>
                                            <el-input v-model="sign_banner.toPage" placeholder="输入时请携带http或https" class="inputstyle"></el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-tab-pane> -->
            </el-tabs>
        </el-form>
         <!-- 选择跳转页面弹框 -->
         <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
            <div>
                <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
            </div>
        </el-dialog>

        <!-- 选择图片弹框 -->
        <UploadSource
            @changeStatus="changeStatus"
            :dialogVisible="chooseImgFlag"
            @getSource="getImgRes"
            :maxSize="1024 * 1024"
        ></UploadSource>
        <Preservation @preservation="save"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import vuedraggable from 'vuedraggable'
import UploadSource from '@/components/uploadSource'
import ToPage from '@/views/addPages/toPage/index'
export default {
    components:{
        Preservation,
        vuedraggable,
        UploadSource,
        ToPage
    },
    data() {
        return {
            tab_active:'banner',
            banner:[{
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
                toPage: '跳转页面',
                choosePagetype:'page'
            }],
            notice:{
                icon:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/notice.png',
                list:[{
                    title:'',
                    toPage:'跳转页面',
                    choosePagetype:'page'
                }]
            },
            sign_banner:{
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
                toPage: '跳转页面',
                choosePagetype:'page'
            },
            chooseindex: -1,
            toPageFlag: false,
            chooseImgFlag: false,
            attr:''
        };
    },
    created() {
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.gateway.indexset.info).then(res => {
                if (res.code == 0) {
                    let {banners,notices,sign_banners} = res.result
                    if(banners)this.banner = banners
                    if(notices)this.notice = notices
                    if(sign_banners)this.sign_banner = sign_banners
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        changeStatus(val) {
            this.chooseImgFlag = val
        },
        addBanner() {
            if (this.banner.length == 5) {
                this.$message({
                    message: '最多只可以添加五张banner图哦~',
                    type: 'error'
                })
                return
            }
            this.banner.push({
                imgURL: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
                toPage: '跳转页面',
                choosePagetype:'page'
            })
        },
        // 点击移动banner图位置
        moveClick(status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                this.$message({
                    message: '已经在最前面了~',
                    type: 'error'
                })
                return
                }
                this.banner.splice(index, 1)
                this.banner.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.banner.length - 1) {
                this.$message({
                    message: '已经在最后面了~',
                    type: 'error'
                })
                return
                }
                this.banner.splice(index + 2, 0, item)
                this.banner.splice(index, 1)
            }
        },
        // 删除对应banner图
        deleteItem(attr,index) {
            if(attr=='banner'){
                this.banner.splice(index, 1)
            }else if(attr=='notice'){
                this.notice.list.splice(index, 1)
            }
        },
        // 选择跳转路径
        choosePage(attr,index=0) {
            this.toPageFlag = true
            this.chooseindex = index
            this.attr = attr
            this.$nextTick(() => {
                this.$refs.toPage.getPageArr()
            })
        },
        // 拿到跳转路径
        getPageRes(item) {
            if(this.attr=='banner'){
                this.banner[this.chooseindex].toPage = item.wxroute
            }else if(this.attr=='notice'){
                this.notice.list[this.chooseindex].toPage = item.wxroute
            }else{
                this.sign_banner.toPage = item.wxroute
            }
            this.toPageFlag = false
        },
        // 拿到图片
        getImgRes(imglist) {
            if(this.attr=='banner'){
                this.banner[this.chooseindex].imgURL = imglist[0].path
            }else if(this.attr=='notice'){
                this.notice.icon = imglist[0].path
            }else{
                this.sign_banner.imgURL = imglist[0].path
            }
        },
        // 选择图片
        chooseImg(attr,index=0){
             this.chooseImgFlag = true
             this.chooseindex = index
             this.attr = attr
        },
        addNotice(){
            if (this.notice.list.length == 10) {
                this.$message({
                message: '最多只可插入10条公告~',
                type: 'error',
                });
                return;
            }
            this.notice.list.push({
                toPage: '跳转页面',
                title: '',
                choosePagetype:'page'
            });
        },
        save(){
            this.$axios.post(this.$api.gateway.indexset.set,{
                banner:this.banner,
                notice:this.notice,
                sign_banner:this.sign_banner
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('保存成功');
                    this.getInfo()
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .el-main {
        background: #fff;
        min-height: 100%;

        .swiperroot{
            position: relative;
            height: 100%;
        }
        .swiperbox{
            width: 324px;
            margin: 0 auto;
            height: 100%;
            .indicator{
                position: absolute;
            }
            img{
                width: 324px;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        .fcccc {
            color: #ccc;
            border: 1px solid #ccc !important;
            cursor: no-drop !important;
        }
        .tips {
            font-size: 14px;
            color: #9a9a9a;
            margin-top: 20px;
        }
        .listItem {
            width: 630px;
        }
        .itemBox {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 20px;
                .left {
                    display: flex;
                    margin-right: 15px;
                    align-items: center;
                    .leftL {
                        font-family: SimSun;
                        width: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;

                        span {
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 5px;
                        display: block;
                        border: 1px solid #333;
                        font-weight: bold;
                        cursor: pointer;
                        }
                        span:first-child {
                        transform: rotate(90deg);
                        }
                        span:last-child {
                        transform: rotate(90deg);
                        margin-top: 10px;
                        }
                    }
                    .leftR {
                        height: 90px;
                        width: 160px;
                        overflow: hidden;
                        position: relative;
                        cursor: pointer;
                        img {
                            width: 160px;
                            height: 90px;
                            object-fit: cover;
                        }
                        .leftR_text{
                            position: absolute;
                            left: 0;
                            right:0;
                            bottom:0;
                            height: 26px;
                            line-height: 26px;
                            font-size: 12px;
                            text-align: center;
                            background-color: rgba(0,0,0,0.4);
                            color: #e4e4e4;
                        }
                    }
                }
                .right {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    .right-top{
                        margin-bottom: 20px;
                    }
                }
            }
        .addOne {
            width: 300px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #3c76ff;
            border: 1px solid #3c76ff;
            cursor: pointer;
            margin-top: 20px;
        }
        .inputstyle{
            width: 300px;
        }
    }

    .pageName {
        display: flex;
        margin-bottom: 20px;
        p {
            width: 100px;
            flex-shrink: 0;
        }
        .el-input{
            width: 300px;
        }
    }
  .itemMid {
    position: relative;
    width: 82px;
    height: 82px;
    border: 1px dotted #333;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgBox {
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin-bottom: 23px;
       object-fit: cover;
    }
    .upload {
      width: 100%;
      line-height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      text-align: center;
    }
  }
  .itemrright{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
       margin-left: 20px;
  }
  .clearImg {
    color: #6d99ff;
    cursor: pointer;
    user-select: none;
  }
</style>
